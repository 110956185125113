.nav-sns{
    background-color: rgb(169, 218, 250);
    text-align: center;
}

.nav-ul-sns{
    margin: 0 ;
    padding: 0.3% ;
}

.nav-ul-li-sns{
    list-style: none;
    display: inline-block;
    width: 18%;
    min-width: 90px;
}

.nav-ul-li-a-sns{
    text-decoration: none;
    color: rgb(0, 105, 175);
    font-weight: bold;
    /* font-size: 1vw; */
}

.nav-ul-li-a-sns:hover{
    color: #f7daba;
}