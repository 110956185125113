.App {
  text-align: center;
}

.App-header {
  background-color: #4473d1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  padding: 0.5%;
}

.header-p {
  font-size:2em;
  line-height:0.95em;
  font-weight:bold;
  color: #FFF;
  text-shadow: 0 0 0.2em rgba(0,0,0,1);
}

.header-p-a {
  color: #FFF;
  text-decoration: none;
}

.App-footer {
  background-color: #4473d1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-main {
  text-align: center;
  margin: 1%;
  /* font-size: 1vw; */
}

.App-main-left {
  margin: 1%;
  /* font-size: 1vw; */
}

.App-main-sp {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; スマホ版は不要 */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-main-pc {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.flex-container {
  background-color: rgb(241, 240, 240);
  padding: 1rem;
  display: flex;
  align-items: flex-start; /* 横軸 */
  justify-content: center; /* 縦軸 */
  flex-wrap: wrap;
}

.non-flex-container {
  background-color: rgb(241, 240, 240);
  padding: 1rem;
}

.Summary-main {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: black;
}

.Summary-table {
  background-color: white;
  border: 1px rgb(216, 216, 216) solid;
  border-radius: 6px;
  height: 100px;
  width: 250px;
  padding: 1%;
  font-size: 2vmin;
}

.Summary-table-logo {
  height: 15px;
  pointer-events: none;
}

.Summary-table-img {
  border-radius: 50%;
}

.basic-icon {
  height: 2vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
}

.span-gray {
  color: #777777;
}

.span-green {
  color: #59df47;
}

.span-red {
  color: #fa3f3f;
}

.span-orange {
  color: #f0a856;
}

.span-bold {
  font-weight: bold;
}

.span-small {
  font-size: 1px;
}

.about-quote {
  background-color: rgb(238, 238, 238);
  border-radius: 10px;
  padding:1%;
}

.contact-table{
  margin: 3px auto;
  border-radius: 10px;
}

.contact-table th{
  position: relative;
  text-align: left;
  padding:7px 0 7px 30px;
  width: 30%;
}

.contact-table td{
  text-align: left;
  padding:7px 0 7px 30px;
  width: 70%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
