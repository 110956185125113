.nav-menu{
    background-color: rgb(255, 255, 255);
    text-align: center;
}

.nav-ul-menu{
    margin: 0 ;
    padding: 0.3% ;
}

.nav-ul-li-menu{
    list-style: none;
    display: inline-block;
    width: 18%;
    min-width: 90px;
}

.nav-ul-li-a-menu{
    text-decoration: none;
    color: rgb(25, 26, 126);
    font-weight: bold;
    /* font-size: 1vw; */
}

.nav-ul-li-a-menu:hover{
    color: #f7c522;
}
