@charset "UTF-8";
summary {
    width: 60%;
    margin: 0 auto 5px;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    background-color: rgb(196, 218, 233);
    cursor: pointer;
    transition: 0.2s;
    color: rgb(25, 26, 126);
    font-weight: bold;
    border-radius: 10px;
}
summary::after {
    font-family: "Font Awesome 5 Free";
    content: "\f0d7";
    font-weight: 900;
    display: flex;
    align-items: center;
    transition: all 0.3s;
}

.details-content {
    background-color: rgb(231, 255, 247);
    width: 60%;
    margin: 0 auto 5px;
    padding: 1%;
    text-align: left;
    border-radius: 10px;
}
.details-content ul {
    margin: 3% auto;
    padding: 1%;
    width: 95%;
    color: rgb(10, 150, 85);
}

details[open] summary:after {
    transform: rotate(180deg);
}

details[open] .details-content {
    animation: fadeIn 0.3s ease;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {
    summary {
        width: 90%;
    }
    .details-content {
        width: 90%;
    }
    .details-content p {
        margin: 10px auto 15px;
    }
}
